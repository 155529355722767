<template>
  <v-card flat v-bind="$attrs" v-if="order">
    <v-row dense align="stretch" justify="space-between">
      <v-col cols="12" md="6" sm="12">
        <div
          class="
            d-flex
            flex-column
            fullwidth
            justify-center
            align-stretch
            fullwidth
          "
        >
          <v-sheet
            class="
              d-flex
              overflow-hidden
              fullwidth
              align-center
              flex-column
              justify-center
              pa-2
            "
          >
            <div class="d-flex align-center justify-center flex-wrap">
              <v-btn
                v-if="order.form.length"
                @click="question_show_common = !question_show_common"
                depressed
                color="primary"
                class="text-none ma-1"
                small
                link
              >
                {{
                  question_show_common
                    ? 'Hide Order Questions'
                    : 'Show Order Questions'
                }}
              </v-btn>
              <v-btn
                @click="question_show_per = !question_show_per"
                depressed
                color="primary"
                class="text-none ma-1"
                small
                link
              >
                {{
                  question_show_per
                    ? 'Hide Per Graphic Questions'
                    : 'Show Per Graphic Questions'
                }}
              </v-btn>
            </div>
            <v-sheet
              v-if="order.form.length"
              :width="mdAndUp ? `90%` : '100%'"
              v-show="question_show_common"
              class="mb-1"
            >
              <FormTableDisplay
                class="fullwidth"
                v-if="order.form"
                :forms="order.form"
              />
              <Empty v-else headline="No common form found" />
            </v-sheet>
            <v-sheet
              :width="mdAndUp ? `90%` : '100%'"
              v-show="question_show_per"
              class="mb-1"
            >
              <FormTableDisplay
                class="fullwidth"
                v-if="activeTemplate && activeTemplate.response_form"
                :forms="activeTemplate.response_form"
              />
              <Empty v-else headline="No unique form found" />
            </v-sheet>
          </v-sheet>
          <v-sheet
            max-width="100%"
            class="d-flex align-center fullwidth justify-space-between my-3"
          >
            <v-btn
              class="mr-1"
              icon
              v-if="order.templates.length > 1"
              @click="carousel--"
            >
              <v-icon color="primary"> mdi-chevron-left </v-icon>
            </v-btn>
            <v-carousel
              v-model="carousel"
              :continuous="true"
              :cycle="false"
              interval="100000"
              :show-arrows="false"
              hide-delimiters
              min-height="400"
              height="100%"
            >
              <v-carousel-item
                v-for="(template, i) in order.templates"
                :key="i"
              >
                <v-sheet
                  color="transparent"
                  max-width="100%"
                  min-height="400"
                  class="
                    rounded-5
                    fullwidth
                    pa-3
                    d-flex
                    align-center
                    justify-center
                  "
                >
                  <Attachment
                    :item="template.attachment"
                    classes="rounded-3"
                    styles="width:auto;max-width:100%; height:auto;max-height:500px;"
                    :prefer-thumb="false"
                  ></Attachment>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
            <v-btn
              class="ml-1"
              icon
              v-if="order.templates.length > 1"
              @click="carousel++"
            >
              <v-icon color="primary"> mdi-chevron-right </v-icon>
            </v-btn>
          </v-sheet>
        </div>
      </v-col>
      <v-col
        :cols="mdAndUp ? 6 : 12"
        :class="{ 'border-left-1 border-color--grey': mdAndUp }"
      >
        <div class="d-flex align-stretch justify-center">
          <v-sheet class="d-flex fullwidth flex-column">
            <v-sheet
              height="20"
              class="
                align-center
                justify-center
                d-flex
                py-2
                primary--text
                fs-17
                fw-700
              "
            >
              <span>Order Comments</span>
            </v-sheet>
            <CommentV2
              flat
              :key="order.id"
              class="fullwidth"
              chatfield-styles="border:1px solid #e0e0e0;padding-top:10px;border-radius:5px;"
              body-max-height="1000px"
              :deletable="false"
              :body-min-height="mdAndUp ? 500 : 300"
              :id="order.id"
              type="order"
            >
            </CommentV2>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CommentV2 from '@/common/Comment/CommentV2.vue'
import FormTableDisplay from '../../components/FormTableDisplay.vue'
export default {
  inheritAttrs: false,
  components: { FormTableDisplay, CommentV2 },
  name: 'SelectedTemplateTab',
  props: {
    order: { type: Object, require: true }
  },
  data: () => ({
    carousel: 0,
    question_show_per: false,
    question_show_common: false,
    activeTemplate: null
  }),
  watch: {
    order: {
      handler: function (val) {
        if (val) this.activeTemplate = val.templates[0] || null
      },
      deep: true,
      immediate: true
    },
    carousel: {
      handler: function (val) {
        this.activeTemplate = this.order.templates[val] || null
      },
      immediate: true
    }
  }
}
</script>

<style></style>
