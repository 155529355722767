<template>
  <v-simple-table v-bind="$attrs" class="border-1 border-color--grey">
    <template v-slot:default>
      <thead class="primary">
        <tr>
          <th class="text-left light--text">Question</th>
          <th class="text-right light--text">Answer</th>
        </tr>
      </thead>
      <tbody v-if="forms">
        <tr v-for="form in final_forms" :key="form.id">
          <td class="text-left primary--text fw-500">{{ form.label }}</td>
          <td class="text-right">
            <div
              class="primary--text fw-700"
              v-if="!['file_upload'].includes(form.type)"
            >
              <span v-if="form.value">
                {{
                  typeof form.value === 'object'
                    ? form.value.join(', ')
                    : form.value
                }}
              </span>
              <span v-else class="text-italic">No answer</span>
            </div>
            <div v-else-if="form.value" class="d-flex align-center justify-end">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    depressed
                    class="text-none mt-1"
                    v-bind="attrs"
                    v-on="on"
                    :href="form.value.url"
                  >
                    <v-icon color="success">mdi-cloud-download</v-icon>
                  </v-btn>
                </template>
                <span>Download File</span>
              </v-tooltip>
              <v-avatar class="my-1" tile size="100">
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  :src="form.value.url_inline"
                ></v-img>
              </v-avatar>
            </div>
            <span v-else class="subtitle-1">No answer</span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'FormTableDisplay',
  props: {
    forms: [String, Array]
  },
  data: () => ({
    final_forms: []
  }),
  watch: {
    forms: {
      handler(val) {
        if (typeof val === 'string') {
          this.final_forms = JSON.parse(val)
        } else {
          this.final_forms = val
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped></style>
