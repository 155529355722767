<template>
  <v-card flat v-bind="$attrs">
    <v-row
      dense
      class="mb-1"
      :style="`background: #e0e0e0`"
      align="center"
      v-for="(template, i) in list"
      :key="i"
    >
      <v-col cols="12" md="6" sm="12" class="d-flex align-stretch">
        <v-sheet
          class="d-flex fullwidth pa-5 align-center flex-column justify-center"
          min-height="200"
          max-height="400"
          max-width="300"
          color="#e0e0e0"
        >
          <span class="primary--text fw-500 mb-3"> Template {{ i + 1 }} </span>
          <Attachment
            :item="template.attachment"
            :height="200"
            :prefer-thumb="false"
          ></Attachment>
        </v-sheet>
        <v-sheet
          class="d-flex fullwidth align-center flex-column justify-center"
          min-height="200"
          max-height="400"
          max-width="300"
          color="#e0e0e0"
        >
          <span class="primary--text fw-500 mb-3">
            Template {{ i + 1 }} Finished
          </span>
          <Attachment
            v-if="template.response"
            :height="200"
            :item="template.response.attachment"
            :prefer-thumb="false"
          ></Attachment>
          <v-chip
            pill
            v-else
            large
            color="white"
            class="px-5 primary--text fw-500"
          >
            No uploaded graphic!
          </v-chip>
        </v-sheet>
      </v-col>
      <v-col
        :cols="mdAndUp ? 6 : 12"
        class="pa-5 d-flex align-center justify-space-between"
      >
        <v-sheet
          class="
            d-flex
            rounded-5
            flex-column
            cursor-pointer
            pa-3
            mr-1
            align-center
            justify-center
          "
          height="180"
          width="250"
          @click="uploadFor(template, i)"
          style="border: 2px dashed #223e6b !important"
          color="white"
        >
          <input
            :accept="settings.offers_files"
            type="file"
            :ref="`file_input_${i}`"
            @change="fileSelectedFor($event, template)"
            class="d-none"
          />
          <span
            class="text-center primary--text fw-500"
            v-if="!template.response"
          >
            Upload finished graphic for template {{ i + 1 }}
          </span>
          <span class="text-center red--text fw-500" v-else>
            Upload new version of graphic for template {{ i + 1 }}
          </span>
        </v-sheet>
        <v-sheet
          class="
            d-flex
            rounded-5
            flex-column
            ml-1
            pa-3
            fullwidth
            align-center
            justify-center
          "
          height="180"
          color="white"
        >
          <span class="text-center primary--text fw-500">
            Template {{ i + 1 }} Status
          </span>
          <v-chip dense class="my-1 px-5 text-center primary--text fw-500">
            {{
              template.response
                ? template.response.status
                : 'for_approval' | ucwords
            }}
          </v-chip>
          <v-btn
            v-if="
              template.response &&
              !['buyer_approved', 'for_approval'].includes(
                template.response.status
              )
            "
            class="text-none my-2"
            depressed
            @click="setResponseStatus('for_approval', template.response)"
            color="primary"
          >
            Ask For Buyer Approval
          </v-btn>
          <v-btn
            v-if="
              template.response && template.response.status === 'buyer_approved'
            "
            class="text-none my-2"
            depressed
            @click="setResponseStatus('seller_approved', template.response)"
            color="primary"
          >
            Approve
          </v-btn>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'UploadFinishedTab',
  props: {
    order: { type: Object, require: true }
  },
  data: () => ({
    uploading: false
  }),
  computed: {
    list() {
      return _.cloneDeep(this.order.templates).map((item) => {
        item.response = this.getResponse(item.attachment)
        return item
      })
    }
  },
  methods: {
    getResponse(template) {
      let index = this.order.responses.findIndex(
        (i) => parseInt(i.orderattachment_id) === parseInt(template.id)
      )
      return ~index ? _.cloneDeep(this.order.responses[index]) : null
    },
    setResponseStatus(status, response) {
      request
        .post(`api/order-responses/${response.id}`, {
          status: status
        })
        .then(({ data }) => {
          this.order.responses = data
          this.appSnackbar('Response status updated')
        })
        .finally(() => {
          this.$emit('updated-order', this.order)
        })
    },
    uploadFor(image, i) {
      this.$refs['file_input_' + i][0].click()
    },
    fileSelectedFor(e, template) {
      this.uploading = true
      let file = e.target.files[0]
      if (file.size < this.settings.fileSizeMaxUploadLimit) {
        let type = file.type.split('/')[0]
        if (!['image', 'video'].includes(type)) {
          this.appSnackbar(
            `${file.name} is not an image or video file.`,
            'error'
          )
        } else {
          let formData = new FormData()
          formData.append('file', file)
          formData.append('orderattachment_id', template.attachment_id)
          request
            .post(`api/orders/${this.order.id}/responses/upload`, formData)
            .then(({ data }) => {
              this.order.responses = data
              this.appSnackbar('Response uploaded')
            })
            .finally(() => (this.uploading = false))
        }
      } else {
        this.appSnackbar(
          `${file.name} filesize exceed ${this.settings.fileSizeMaxUploadInMB}MB.`,
          'error'
        )
      }
    }
  }
}
</script>

<style></style>
