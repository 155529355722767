<template>
  <v-card
    flat
    outlined
    class="rounded-10 view-order-page"
    v-if="order"
    min-height="500"
  >
    <v-card-title class="mb-2 d-flex align-center justify-space-between">
      <div class="title primary--text">
        Order No. OP-0{{ order.id }}0
        <v-icon color="primary" class="mx-1">mdi-chevron-right</v-icon>
        <strong class="fw-900"> {{ order.product.title | ucwords }} </strong>
      </div>
      <v-btn icon @click="$router.go(-1)">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row
        dense
        align="center"
        justify="center"
        class="border-bottom-1 border-color--grey"
      >
        <v-col :cols="mdAndUp ? 5 : 12">
          <v-sheet class="fullwidth">
            <v-tabs center-active v-model="tab">
              <v-tab
                active-class="border-0 fw-900"
                href="#selected_templates"
                class="text-none"
              >
                Selected Templates
              </v-tab>
              <v-tab
                active-class="border-0 fw-900"
                href="#responses"
                class="text-none"
              >
                Responses
              </v-tab>
              <v-tab
                v-if="is_seller_side"
                active-class="border-0 fw-900"
                href="#upload_final"
                class="text-none"
              >
                Upload
              </v-tab>
            </v-tabs>
          </v-sheet>
        </v-col>
        <v-col
          :cols="mdAndUp ? 7 : 12"
          :class="{ 'flex-column': !mdAndUp }"
          class="d-flex align-center v-row dense justify-start"
        >
          <div
            class="d-flex col-md-4 align-center justify-center primary--text"
          >
            <span class="fw-600 mr-1">Purchased Price : </span>
            <strong class="fw-900 fs-15">{{ order.buy_price | money }}</strong>
            <v-icon
              small
              right
              title="Paid"
              v-if="order.is_paid"
              color="success"
            >
              mdi-cash-check
            </v-icon>
            <v-icon small right title="Not yet paid" v-else color="error">
              mdi-cash-remove
            </v-icon>
          </div>
          <div
            v-if="!is_seller_side"
            class="d-flex col-md-4 align-center justify-center primary--text"
          >
            <span class="fw-600 mr-1">Project : </span>
            <v-menu
              :nudge-width="150"
              v-if="order.orderprojects.length <= 0"
              offset-y
              left
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="primary"
                      depressed
                      class="text-none fw-700"
                      small
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      Attach
                      <v-icon small right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Attach to Project</span>
                </v-tooltip>
              </template>
              <v-list dense max-height="350" style="overflow-y: auto">
                <v-list-item
                  v-for="(item, i) in projects"
                  :key="i"
                  dense
                  @click="attach_to(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="primary--text fw-700">
                      {{
                        item.type === 'campaign'
                          ? item.props.business_name
                          : item.title | ucwords
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="primary--text">
                      {{ `${item.type} Project, ID: ${item.id}` | ucwords }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-chip
              v-else
              :title="
                item.type === 'business' ? item.props.business_name : item.title
              "
              color="primary"
              label
              small
              :close="!is_seller_side"
              v-for="(item, ii) in order.orderprojects"
              @click:close="remove_from(item)"
              :key="ii"
            >
              <span class="caption">
                {{
                  item.type === 'business'
                    ? item.props.business_name
                    : item.title | truncate(15)
                }}
              </span>
            </v-chip>
          </div>
          <div
            class="d-flex col-md-4 align-center justify-center primary--text"
          >
            <span class="fw-600 mr-1">Status : </span>
            <strong class="fw-900 fs-15" :class="order.status">
              {{ order.status | ucwords }}
            </strong>
          </div>
        </v-col>
      </v-row>
      <v-sheet flat class="mt-1" color="grey">
        <v-tabs-items v-model="tab">
          <!-- Selected Template tab -->
          <v-tab-item value="selected_templates" class="pa-md-5 pa-sm-1">
            <SelectedTemplateTab :order="order"></SelectedTemplateTab>
          </v-tab-item>
          <!-- Responses tab -->
          <v-tab-item value="responses" class="pa-md-5 pa-sm-1">
            <ResponsesTab
              :order="order"
              @updated-order="order = $event"
            ></ResponsesTab>
          </v-tab-item>
          <!-- Upload tab -->
          <v-tab-item
            value="upload_final"
            class="pa-md-5 pa-sm-1"
            v-if="is_seller_side"
          >
            <UploadFinishedTab
              :order="order"
              @updated-order="order = $event"
            ></UploadFinishedTab>
          </v-tab-item>
        </v-tabs-items>
      </v-sheet>
      <!-- More Info -->
      <MoreInfo :order="order"></MoreInfo>
    </v-card-text>
  </v-card>
  <Empty v-else-if="!loading" headline="No order found!"></Empty>
  <PageLoader v-else min-height="500"></PageLoader>
</template>

<script>
import request from '@/services/axios_instance'
import { mapGetters } from 'vuex'
import ResponsesTab from './order-components/Responses.vue'
import SelectedTemplateTab from './order-components/SelectedTemplate.vue'
import UploadFinishedTab from './order-components/UploadFinished.vue'
import MoreInfo from './order-components/MoreInfo.vue'
export default {
  name: 'OrderDetails',
  components: {
    ResponsesTab,
    SelectedTemplateTab,
    UploadFinishedTab,
    MoreInfo
  },
  data: () => ({
    tab: 'selected_templates',
    order: null,
    loading: false,
    uploaded: [],
    paths: [
      {
        text: 'Dashboard',
        disabled: false,
        route: { name: 'default-content' }
      },
      {
        text: 'Offers',
        disabled: false,
        route: { name: 'offers', params: { type: 'products' } }
      },
      { text: 'Orders', disabled: true, route: null }
    ],
    projects: []
  }),
  watch: {
    '$route.params.id': {
      handler: function (val) {
        this.getOrder(val)
      },
      immediate: true
    },
    order: {
      handler: function (val) {
        if (val) {
          this.getProjects(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    is_seller_side() {
      if (!this.order) return false
      let ids = this.order.orderhandlers.map((i) => {
        return i.id
      })
      if (ids && ids.includes(this.user.id)) return true
      return this.user.id === this.order.product.user_id
    },
    isDisabled() {
      return (
        this.uploaded &&
        this.uploaded.filter((i) => !i.hasOwnProperty('id')).length === 0
      )
    },
    isOrderCompleted() {
      return this.order && this.order.status.toLowerCase() === 'completed'
    },
    isOrderCancelled() {
      return this.order && this.order.status.toLowerCase() === 'cancelled'
    }
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  methods: {
    getProjects(order) {
      if (order) {
        request
          .get(`api/projects/user-company-projects/${order.user_id}`)
          .then(({ data }) => {
            this.projects = data
          })
      }
    },
    getOrder(id) {
      this.loading = true
      request
        .get(`api/orders/${id}`)
        .then(({ data }) => {
          this.order = data
        })
        .finally(() => (this.loading = false))
    },
    attach_to(item) {
      if (!this.order) return
      let payload = {
        order_id: this.order.id,
        item_id: item.id
      }
      request
        .post(`api/orders/${payload.order_id}/order-projects/attach`, payload)
        .then(({ data }) => {
          if (!this.order.orderprojects) this.order.orderprojects = []
          this.order.orderprojects.push(data)
          this.appSnackbar(
            `Successfully attach to ${
              item.type === 'campaign' ? item.props.business_name : item.title
            }`
          )
        })
    },
    remove_from(item) {
      if (!this.order) return
      let payload = {
        order_id: this.order.id,
        item_id: item.id
      }
      request
        .post(`api/orders/${payload.order_id}/order-projects/detach`, payload)
        .then(({ data }) => {
          let index = this.order.orderprojects.findIndex(
            (i) => i.id === item.id
          )
          if (~index) {
            this.order.orderprojects.splice(index, 1)
            this.appSnackbar(
              `Successfully detach from ${
                item.type === 'campaign' ? item.props.business_name : item.title
              }`
            )
          }
        })
    }
  }
}
</script>
<style>
.view-order-page .v-tabs-slider-wrapper {
  display: none;
}
</style>
<style scoped lang="scss">
.view-order-page {
  .to_pay {
    color: red !important;
  }
}
</style>
