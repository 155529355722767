<template>
  <v-card flat v-bind="$attrs">
    <v-row dense align="stretch" justify="space-between">
      <v-col :cols="mdAndUp ? 6 : 12">
        <div
          class="d-flex align-center justify-space-between my-3"
          v-if="order.responses.length"
        >
          <v-btn
            class="mr-1"
            icon
            v-if="order.responses.length > 1"
            @click="prev"
          >
            <v-icon color="primary"> mdi-chevron-left </v-icon>
          </v-btn>
          <div class="mx-auto fullwidth">
            <ImageAnnotator
              v-show="canAnnotate(activeAttachment)"
              :has-header="false"
              :key="activeAttachment.uuid"
              min-height="500"
              :attachment="activeAttachment"
            ></ImageAnnotator>
            <v-sheet
              v-if="!canAnnotate(activeAttachment)"
              min-height="500"
              class="rounded-5 pa-3 d-flex align-center justify-center"
            >
              <Attachment
                :item="activeAttachment"
                styles="width:auto; height:auto;max-height:500px;"
                :prefer-thumb="false"
                class="mx-auto"
              ></Attachment>
            </v-sheet>
          </div>
          <v-btn
            class="ml-1"
            icon
            v-if="order.responses.length > 1"
            @click="next"
          >
            <v-icon color="primary"> mdi-chevron-right </v-icon>
          </v-btn>
        </div>
        <Empty
          :min-height="500"
          v-if="order.responses.length === 0"
          headline="No responses yet"
        ></Empty>
        <span v-else class="primary--text fw-900">
          {{ carousel + 1 }}/{{ order.responses.length }}
        </span>
        <div
          class="d-flex align-center justify-center flex-wrap"
          v-if="order.responses.length > 0 && activeResponse"
        >
          <v-btn disabled class="text-none primary--text mx-1 ls-unset">
            Status : {{ activeResponse.status | ucwords }}
          </v-btn>
          <v-btn
            depressed
            :disabled="
              activeResponse.status === 'mutually_approved' ||
              activeResponse.status === 'for_modification'
            "
            @click="setResponseStatus('for_modification')"
            class="text-none ma-1 ls-1"
            color="primary"
            v-if="!is_seller_side"
          >
            For Modification
          </v-btn>
          <v-btn
            depressed
            :disabled="
              activeResponse.status === 'mutually_approved' ||
              activeResponse.status === 'buyer_approved'
            "
            @click="setResponseStatus('buyer_approved')"
            class="text-none ma-1 ls-1"
            color="primary"
            v-if="!is_seller_side"
          >
            Approve
          </v-btn>
          <v-btn
            depressed
            class="text-none ma-1 ls-1"
            color="primary"
            :href="activeResponse.orderattachment.url"
          >
            Download
          </v-btn>
        </div>
      </v-col>
      <v-col
        :cols="mdAndUp ? 6 : 12"
        :class="{ 'border-left-1 border-color--grey': mdAndUp }"
      >
        <div class="d-flex align-stretch justify-center">
          <v-sheet class="d-flex fullwidth flex-column">
            <v-sheet
              height="20"
              class="
                align-center
                justify-center
                d-flex
                py-2
                primary--text
                fs-17
                fw-700
              "
            >
              <span>Response Template Comments</span>
            </v-sheet>
            <CommentV2
              flat
              v-if="activeResponse"
              :key="activeResponse.id"
              class="fullwidth"
              chatfield-styles="border:1px solid #e0e0e0;padding-top:10px;border-radius:5px;"
              body-max-height="1000px"
              :deletable="false"
              :body-min-height="mdAndUp ? 500 : 300"
              :id="activeResponse.id"
              type="order_response"
            >
            </CommentV2>
            <Empty
              v-else
              :min-height="470"
              headline="No commentable template!"
              icon="mdi-message-bulleted-off"
            ></Empty>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import CommentV2 from '@/common/Comment/CommentV2.vue'
import { mapGetters } from 'vuex'
import ImageAnnotator from '@/common/Editor/ImageAnnotator.vue'
export default {
  inheritAttrs: false,
  components: { CommentV2, ImageAnnotator },
  name: 'ReponsesTab',
  props: {
    order: { type: Object, require: true }
  },
  data: () => ({
    carousel: 0,
    isAnnotationMode: false
  }),
  computed: {
    ...mapGetters(['user']),
    is_seller_side() {
      if (!this.order) return false
      let ids = this.order.orderhandlers.map((i) => {
        return i.id
      })
      if (ids && ids.includes(this.user.id)) return true
      return this.user.id === this.order.product.user_id
    },
    activeAttachment() {
      return this.order.responses.length
        ? this.order.responses[this.carousel].attachment
        : null
    },
    activeResponse() {
      return this.order.responses.length
        ? this.order.responses[this.carousel]
        : null
    }
  },
  watch: {
    order: {
      handler: function (val) {
        if (val) this.activeResponse = val.responses[0] || null
      },
      deep: true,
      immediate: true
    },
    carousel: {
      handler: function (val) {
        this.activeResponse = this.order.responses[val] || null
      },
      immediate: true
    }
  },
  methods: {
    canAnnotate(item) {
      return item &&
        ['png', 'jpg', 'gif', 'jpeg', 'webp'].includes(item.file_ext)
        ? true
        : false
    },
    prev() {
      if (this.carousel === 0) {
        this.carousel = this.order.responses.length - 1
      } else {
        this.carousel--
      }
    },
    next() {
      if (this.carousel === this.order.responses.length - 1) {
        this.carousel = 0
      } else {
        this.carousel++
      }
    },
    setResponseStatus(status) {
      let activeId = this.activeResponse.id
      request
        .post(`api/order-responses/${activeId}`, {
          status: status
        })
        .then(({ data }) => {
          this.order.responses = data
          this.appSnackbar('Response status updated')
          this.$nextTick(() => {
            this.activeResponse = this.order.responses.find(
              (i) => i.id === activeId
            )
          })
        })
        .finally(() => {
          this.$emit('updated-order', this.order)
        })
    }
  }
}
</script>

<style></style>
