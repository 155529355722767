<template>
  <v-row color="transparent" class="my-3" align="stretch" justify="center">
    <v-col
      cols="12"
      md="2"
      sm="12"
      class="border-1 ma-1 border-color--grey rounded-5"
    >
      <h4 class="fw-500 fs-14 text-center secondary--text">Ordered By</h4>
      <div class="d-flex align-center mt-2 flex-column">
        <v-avatar class="border-3 border-color--primary">
          <v-img
            :lazy-src="settings.loader"
            contain
            :src="order.from.image_url || tempImage"
          ></v-img>
        </v-avatar>
        <div class="d-flex flex-column text-center ml-2 primary--text fs-15">
          <span class="fw-700">{{ order.from.fullname | ucwords }}</span>
          <span class="fw-500">
            {{ order.from.company.name | ucwords }}
          </span>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="2"
      sm="12"
      class="border-1 ma-1 border-color--grey rounded-5"
    >
      <h4 class="fw-500 fs-14 text-center secondary--text">Seller Info</h4>
      <div class="d-flex align-center mt-2 flex-column">
        <v-avatar class="border-3 border-color--primary">
          <v-img
            contain
            :src="order.product.company.company_logo || tempImage"
          ></v-img>
        </v-avatar>
        <div class="d-flex flex-column text-center ml-2 primary--text fs-15">
          <span class="fw-700">
            {{ order.product.company.name | ucwords }}
          </span>
          <span class="fw-500">
            {{ order.product.company.domain || '-' }}
          </span>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="3"
      sm="12"
      class="border-1 ma-1 border-color--grey rounded-5"
    >
      <h4 class="fw-500 fs-14 secondary--text text-center">Progress</h4>
      <div class="d-flex flex-column ml-2 primary--text fs-15">
        <div class="d-flex align-center mb-1 justify-space-between">
          <span class="fw-500 fs-15">Selected Templates</span>
          <span class="fw-500 fs-20">
            {{ order.templates.length }}
          </span>
        </div>
        <div class="d-flex align-center mb-1 green--text justify-space-between">
          <span class="fw-500 fs-15">Approved Templates</span>
          <span class="fw-500 fs-20">{{ approved_designs.length }}</span>
        </div>
        <div class="d-flex align-center mb-1 red--text justify-space-between">
          <span class="fw-500 fs-15">Pending Templates</span>
          <span class="fw-500 fs-20">{{ pending_designs.length }}</span>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="3"
      sm="12"
      class="border-1 ma-1 border-color--grey rounded-5"
    >
      <h4 class="fw-500 fs-14 secondary--text text-center">Order Actions</h4>
      <div
        class="
          d-flex
          align-center
          justify-center
          ml-2
          flex-column
          primary--text
          fs-15
        "
      >
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleMarkCompleteOrder"
              class="text-none my-1"
              v-bind="attrs"
              v-on="on"
              block
              :disabled="order.status === 'cancelled'"
              depressed
              color="primary"
              small
            >
              Mark as Complete
            </v-btn>
          </template>
          <div class="white--text fs-12">
            Marking order as complete will close the order and all finished<br />
            template status will be set to MUTUALLY APPROVED status.
          </div>
        </v-tooltip>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              block
              @click="handleCancelOrder"
              :disabled="
                approved_designs.length > 0 ||
                order.status === 'cancelled' ||
                true
              "
              class="text-none my-1 d-none"
              v-bind="attrs"
              v-on="on"
              depressed
              color="error"
              small
            >
              Cancel Order
            </v-btn>
          </template>
          <div class="white--text fs-12">
            Cancelling order will generate partial/full refunds. <br />
            Cancelling order that has approved finished graphics is not allowed
          </div>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import request from '@/services/axios_instance'
export default {
  inheritAttrs: false,
  name: 'MoreInfo',
  props: {
    order: { type: Object, require: true }
  },
  computed: {
    tempImage() {
      return require('@/assets/temp/no-image-preview.png')
    },
    approved_designs() {
      if (!this.order) return []
      return this.order.responses.filter((i) =>
        ['mutually_approved', 'buyer_approved'].includes(i.status)
      )
    },
    pending_designs() {
      if (!this.order) return []
      return this.order.responses.filter(
        (i) => !['mutually_approved', 'buyer_approved'].includes(i.status)
      )
    }
  },
  methods: {
    handleCancelOrder() {
      alert('Todo')
    },
    handleMarkCompleteOrder() {
      alert('Todo')
    }
  }
}
</script>

<style></style>
